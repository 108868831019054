/** Imports des modules**/
import {AxiosAuth} from "../CallerApi";

let allCentral = function() {
    return AxiosAuth.post('/all-central')
}

let addCentral = function(centralInfo) {
    return AxiosAuth.post('/add-central',centralInfo)
}
let updateCentral = function(centralInfo) {
    return AxiosAuth.post(`/update-central/${centralInfo.id}`,centralInfo)
}

let deleteCentral = function(centralId) {
    return AxiosAuth.post('/delete-central',centralId)
}


export const CentralApi = {
  allCentral,
    addCentral,
    updateCentral,
    deleteCentral
}