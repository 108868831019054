import api from '../../serviceApi/apiService'

export default {
namespace: true,

    state: {
            status_user:0,
            status_compte:false,
            token_user:null,
            message_user:'',
            users:{
                id: 0,
                id_compte: '',
                type_compte: '',
                identifiant:'',
                created_at: '',
                 updated_at: ''

            },
},
//======== Affichage des données de l'utilisateur ========//
getters: {
    getStatusUser(state){
        return state.status_user
    },
    getStatusCompte(state){
      return state.status_compte
  },
   getTokenUser(state){
    return state.token_user     
   },
    getMessageUser(state){
        return state.message_user
    },

      getUser(state) {
        return state.user;
      },
},
//========= recuperation  des données de l'utilisateur ========//
mutations: {

    
      setUser(state, value) {
        state.user = value;
      },
      setStatusUser(state, value) {
        state.status_user = value;
      },
      setStatusCompte(state, value) {
        state.status_compte = value;
      },
      setTokenUser(state, value) {
        state.token_user = value;
      },
      setMessageUser(state, value) {
        state.message_user = value;
      }


},

//=====fait action des donnees========
actions: {
  
  login : async function ({commit},datas) {
    let fd = new FormData();
    fd.append('identifiant', datas.identifiant);
    fd.append('password', datas.password);
     const data = await api.createUpdatedata('auth/login',fd)
      commit('setStatusUser', data.status)
      commit('setTokenUser', data.token)
      commit('setMessageUser', data.message)
      commit('setUser', data.user)
      if (data.status==200) {
        commit('setStatusCompte', true)
      }
      console.log(data);
  },


}



}