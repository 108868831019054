import { SuperviseurAreaApi }   from "@/services";
export const SupervisorAreaModule={
    state:{
        onlySupervisor: [],
        statusCodeGetOnlySupervisor: '',
        messageGetOnlySupervisor: '',
        statusSaveAreaSupervisor: '',
        messageSaveAreaSupervisor: '',
        statusUpdateAreaSupervisor: '',
        messageUpdateAreaSupervisor: '',
        statusDeleteAreaSupervisor: '',
        messageDeleteAreaSupervisor: '',
        allGlobalGeographie:'',
        statusAllGlobalGeographie:'',
        messageAllGlobalGeographie:''

    },

    actions:{
        onlySuperviseur: ({commit}, superviseurId) => {
            commit('setStatusCodeGetSupervisor','loading_get_only_supervisor')

            SuperviseurAreaApi.onlySuperviseurArea(superviseurId)  // Send a POST request
                .then(response => {
                    commit('setOnlySupervisor',response.data)
                    commit('setStatusCodeGetOnlySupervisor',response.status);
                    commit('setMessageGetOnlySupervisor',response.statusText);
                    console.log(response.data)
                    console.log(response.status)
                })

                // eslint-disable-next-line no-unused-vars
                .catch(error => {
                    console.log(error.message)
                    commit('setStatusCodeGetOnlySupervisor','failure_get_only_supervisor')
                    commit('setMessageGetOnlySupervisor',error.message);
                });

            // })


        },
        allGlobalGeographie: ({commit}, ) => {
            commit('setStatusCodeGetSupervisor','loading_get_all_global_geographie')

            SuperviseurAreaApi.allGlobalGeographie()  // Send a POST request
                .then(response => {
                    commit('setAllGlobalGeographie',response.data)
                    commit('setStatusCodeAllGlobalGeographie',response.status);
                    commit('setMessageAllGlobalGeographie',response.statusText);
                    console.log(response.data)
                    console.log(response.status)
                })

                // eslint-disable-next-line no-unused-vars
                .catch(error => {
                    console.log(error.message)
                    commit('setStatusCodeAllGlobalGeographie','failure_get_all_global_geographie')
                    commit('setMessageAllGlobalGeographie',error.message);
                });

            // })


        },

        saveAreaSupervisor: ({commit}, supervisorInfo) => {
            commit('setStatusSaveAreaSupervisor','loading_save_supervisor_area')
            return new Promise((resolve, reject) => {

                // Send a POST request
                SuperviseurAreaApi.addSuperviseurArea(supervisorInfo)  // Send a POST request
                    .then(response => {

                        // commit('setUser',response.data)
                        resolve(response.data)
                        commit('setStatusSaveAreaSupervisor',response.data.status)
                        commit('setMessageSaveAreaSupervisor',response.data.message)
                    })

                    .catch(error => {
                        reject(error.message)
                        reject(error)
                    });

            })


        },

        updateSupervisor: ({commit}, supervisorInfo) => {
            commit('setStatusUpdateAreaSupervisor','loading_update_supervisor')
            return new Promise((resolve, reject) => {

                // Send a POST request
                SuperviseurAreaApi.updateSuperviseur(supervisorInfo)  // Send a POST request
                    .then(response => {

                        resolve(response.data)
                        commit('setMessageUpdateAreaSupervisor',response.data.message)
                        commit('setStatusUpdateAreaSupervisor',response.data.status)

                    })

                    .catch(error => {
                        reject(error)
                        commit('setStatusUpdateAreaSupervisor',error.message)
                    });

            })


        },

        deleteSupervisor: ({commit}, supervisorId) => {
            commit('setStatusDeleAreateSupervisor','loading_delete_supervisor')
            return new Promise((resolve, reject) => {

                // Send a POST request
                SuperviseurAreaApi.deleteSuperviseur(supervisorId)  // Send a POST request
                    .then(response => {
                        resolve(response.data)
                        commit('setMessageDeleteAreaSupervisor',response.data.message)
                        commit('setMessageDeleteAreaSupervisor',response.data.message)


                    })

                    .catch(error => {
                        reject(error)
                        commit('setStatusDeleteAreaSupervisor',error.message)
                    });

            })


        },

        updateStatusSupervisor: ({commit}, supervisorInfo) => {
            commit('setStatusDeleteSupervisor','loading_delete_supervisor')
            return new Promise((resolve, reject) => {

                // Send a POST request
                SuperviseurAreaApi.updateStatusSuperviseur(supervisorInfo)  // Send a POST request
                    .then(response => {
                        resolve(response.data)
                        commit('setMessageUpdateStatusSupervisor',response.data.message)
                        commit('setMessageUpdateStatusSupervisor',response.data.status)

                    })

                    .catch(error => {
                        reject(error)
                        commit('setStatusUpdateStatusSupervisor',error.message)
                    });

            })


        },



    },

    mutations:{
        setOnlySupervisor(state,onlySupervisor){
            state.onlySupervisor = onlySupervisor;
        },
        setAllGlobalGeographie(state,allGlobalGeographie){
            state.allGlobalGeographie = allGlobalGeographie;
        },

        setStatusCodeGetOnlySupervisor(state,statusCodeGetOnlySupervisor){
            state.statusCodeGetOnlySupervisor = statusCodeGetOnlySupervisor;
        },
        setMessageGetOnlySupervisor(state,messageGetOnlySupervisor){
            state.messageGetOnlySupervisor = messageGetOnlySupervisor;
        },
        setStatusSaveAreaSupervisor(state,statusSaveAreaSupervisor){
            state.statusSaveAreaSupervisor = statusSaveAreaSupervisor
        },
        setMessageSaveAreaSupervisor(state,messageSaveAreaSupervisor){
            state.messageSaveAreaSupervisor = messageSaveAreaSupervisor;
        },
        setStatusUpdateAreaSupervisor(state,statusUpdateAreaSupervisor){
            state.statusUpdateAreaSupervisor = statusUpdateAreaSupervisor;
        },
        setMessageUpdateAreaSupervisor(state,messageUpdateAreaSupervisor){
            state.messageUpdateAreaSupervisor = messageUpdateAreaSupervisor;
        },
        setStatusDeleteAreaSupervisor(state,statusDeleteAreaSupervisor){
            state.statusDeleteAreaSupervisor = statusDeleteAreaSupervisor;
        },
        setStatusCodeAllGlobalGeographie(state,statusAllGlobalGeographie){
            state.statusAllGlobalGeographie = statusAllGlobalGeographie;
        },
        setMessageDeleteAreaSupervisor(state,messageDeleteAreaSupervisor){
            state.messageDeleteAreaSupervisor = messageDeleteAreaSupervisor;
        },
        setMessageAllGlobalGeographie(state,messageAllGlobalGeographie){
            state.messageAllGlobalGeographie = messageAllGlobalGeographie;
        },

    },

    getters:{

        getOnlySupervisor(state){return state.onlySupervisor},
        getAllGlobalGeographie(state){return state.allGlobalGeographie},
        getStatusCodeOnlySupervisor(state){return state.statusCodeGetSupervisor},
        getMessageOnlySupervisor(state){return state.messageGetOnlySupervisor},
        getStatusSaveAreaSupervisor(state){return state.statusSaveAreaSupervisor},
        getMessageSaveAreaSupervisor(state){return state.messageSaveAreaSupervisor},
        getStatusUpdateAreaSupervisor(state){return state.statusUpdateAreaSupervisor},
        getMessageUpdateAreaSupervisor(state){return state.messageUpdateAreaSupervisor},
        getStatusDeleteAreaSupervisor(state){return state.statusDeleteAreaSupervisor},
        getMessageDeleteAreaSupervisor(state){return state.messageDeleteAreaSupervisor},




    }
}