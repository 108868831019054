/** Imports des modules**/
import {AxiosAuth} from "../CallerApi";

let allSuperviseur = function() {
    return AxiosAuth.post('/all-superviseur')
}

let addSuperviseur = function(superviseurInfo) {
    return AxiosAuth.post('/add-superviseur',superviseurInfo)
}
let updateSuperviseur = function(superviseurInfo) {
    return AxiosAuth.post(`/update-superviseur/${superviseurInfo.id}`,superviseurInfo)
}

let deleteSuperviseur = function(superviseurId) {
    return AxiosAuth.post(`/delete-superviseur`,superviseurId)
}
let updateStatusSuperviseur = function(superviseurId) {
    return AxiosAuth.post(`/etat-superviseur`,superviseurId)
}



export const SuperviseurApi = {
  allSuperviseur,
    addSuperviseur,
    updateSuperviseur,
    deleteSuperviseur,
    updateStatusSuperviseur
}