import {CentralApi}   from "@/services";
export const CentralModule={
    state:{
        allCentral: [],
        statusCodeGetAllCentral: '',
        // messageGetAllCentral: '',
        statusSaveCentral: '',
        statusUpdateCentral: '',
        statusDeleteCentral: '',

    },
    actions:{
        allCentral: ({commit}) => {
            commit('setStatusCodeGetAllCentral','loading_get_all_central')

            CentralApi.allCentral()  // Send a POST request
                .then(response => {
                    commit('setAllCentral',response.data)
                    commit('setStatusCodeGetAllCentral',response.data.status);

                    console.log(response.data)
                    console.log(response.data.status)

                })

                // eslint-disable-next-line no-unused-vars
                .catch(error => {
                    commit('setStatusCodeGetAllCentral','failure_get_all_central');
                });

            // })


        },

        saveCentral: ({commit}, centralInfo) => {
            commit('setStatusSaveCentral','loading_save_central')
            return new Promise((resolve, reject) => {

                // Send a POST request
                CentralApi.addCentral(centralInfo)  // Send a POST request
                    .then(response => {
                        commit('setStatusSaveCentral','success_save_central')
                        // commit('setUser',response.data)
                        resolve(response.data)
                    })

                    .catch(error => {
                        commit('setStatusSaveCentral','failure_save_central')
                        reject(error)
                    });

            })


        },

        udapteCentral: ({commit}, centralInfo) => {
            commit('setStatusUpdateCentral','loading_update_central')
            return new Promise((resolve, reject) => {

                // Send a POST request
                CentralApi.updateCentral(centralInfo)  // Send a POST request
                    .then(response => {
                        commit('setStatusUpdateCentral','success_update_central')
                        resolve(response.data)

                    })

                    .catch(error => {
                        commit('setStatusUpdateCentral','failure_update_central')
                        reject(error)
                    });

            })


        },

        deleteCentral: ({commit}, centralId) => {
            commit('setStatusDeleteCentral','loading_delete_central')
            return new Promise((resolve, reject) => {

                // Send a POST request
                CentralApi.deleteCentral(centralId)  // Send a POST request
                    .then(response => {
                        commit('setStatusDeleteCentral','success_delete_central');
                        // commit('setAllCentral',response.data)
                        resolve(response.data)
                    })

                    .catch(error => {
                        commit('setStatusDeleteCentral','failure_delete_central');
                        reject(error)
                    });

            })

        },



    },
    mutations:{
        setAllCentral(state,allCentral){
            state.allCentral = allCentral;
        },
        setStatusCodeGetAllCentral(state,statusCodeGetAllCentral){
            state.statusCodeGetAllCentral = statusCodeGetAllCentral;
        },
        setStatusSaveCentral(state,statusSaveCentral){
            state.statusSaveCentral = statusSaveCentral;
        }
        ,
        setStatusUpdateCentral(state,statusUpdateCentral){
            state.statusUpdateCentral = statusUpdateCentral;
        },
        setStatusDeleteCentral(state,statusDeleteCentral){
            state.statusDeleteCentral = statusDeleteCentral;
        }
    },
    getters:{
        getAllCentral(state){return state.allCentral},
        getStatusCodeAllCentral(state){return state.statusCodeGetAllCentral},
        getStatusSaveCentral(state){return state.statusSaveCentral},
        getStatusUpdateCentral(state){return state.statusUpdateCentral},
        getStatusDeleteCentral(state){return state.statusDeleteCentral},

    }
}