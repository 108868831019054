/** Imports des modules**/
import {AxiosParametre} from "../CallerApi";
//https://ebaseapi.piebarometre.digital/api/parametre/all-grobal-geographie
let onlySuperviseurArea = function(superviseurId) {
    return AxiosParametre.post('/only-zone-intervention-superviseur', superviseurId)
}
let allGlobalGeographie = function() {
    return AxiosParametre.post('/all-grobal-geographie')
}

let addSuperviseurArea = function(superviseurInfo) {
    return AxiosParametre.post('/add-zone-intervention-superviseur', superviseurInfo)
}
let updateSuperviseurArea = function(superviseurInfo) {
    return AxiosParametre.post(`/update-zone-intervention-superviseur/${superviseurInfo.id}`, superviseurInfo)
}

let deleteSuperviseurArea = function(superviseurId) {
    return AxiosParametre.post(`/delete-zone-intervention-superviseur`, superviseurId)
}


export const SuperviseurAreaApi = {
    allGlobalGeographie,
    onlySuperviseurArea,
    addSuperviseurArea,
    updateSuperviseurArea,
    deleteSuperviseurArea
}