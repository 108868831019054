/** Imports  des modules necessaires **/
import axios from 'axios';
// import {LoginApi} from '@/services'

export const AxiosAuth = axios.create({  baseURL: 'https://ebaseapi.piebarometre.digital/api/auth/' });
export const AxiosParametre = axios.create({  baseURL: 'https://ebaseapi.piebarometre.digital/api/parametre/' });

//on intercepte la request, on injecte le token et on relache la request
// AxiosAuth().interceptors.request.use(request => {
//     // console.log(request)
//
//     // on injecte le token si isLogged
//     if(LoginApi.isLogged) {
//         request.headers.Authorization = 'Bearer ' + LoginApi.getToken();
//     }
//     // console.log(request)
//
//     //on relache la request
//     return request;
// })//on exporte
export default {
    AxiosAuth,
    AxiosParametre
}
