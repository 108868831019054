import { SuperviseurApi }   from "@/services";
export const SupervisorModule={
    state:{
        allSupervisor: [],
        statusCodeGetSupervisor: '',
        messageGetSupervisor: '',
        statusSaveSupervisor: '',
        messageSaveSupervisor: '',
        statusUpdateSupervisor: '',
        messageUpdateSupervisor: '',
        statusDeleteSupervisor: '',
        messageDeleteSupervisor: '',
        messageUpdateStatusSupervisor: '',
        statusUpdateStatusSupervisor: '',

    },
    actions:{
        allSuperviseur: ({commit}) => {
            commit('setStatusCodeGetSupervisor','loading_get_all_supervisor')

            SuperviseurApi.allSuperviseur()  // Send a POST request
                .then(response => {
                    commit('setAllSupervisor',response.data)
                    commit('setStatusCodeGetSupervisor',response.status);
                    commit('setMessageGetSupervisor',response.statusText);
                    console.log(response)
                    console.log(response.status)
                })

                // eslint-disable-next-line no-unused-vars
                .catch(error => {
                    console.log(error.message)
                    commit('setStatusCodeGetSupervisor','failure_get_all_supervisor')
                    commit('setMessageGetSupervisor',error.message);
                });

            // })


        },

        saveSupervisor: ({commit}, supervisorInfo) => {
            commit('setStatusSaveSupervisor','loading_save_central')
            return new Promise((resolve, reject) => {

                // Send a POST request
                SuperviseurApi.addSuperviseur(supervisorInfo)  // Send a POST request
                    .then(response => {

                        // commit('setUser',response.data)
                        resolve(response.data)
                        commit('setStatusSaveSupervisor',response.data.status)
                        commit('setMessageSaveSupervisor',response.data.message)
                    })

                    .catch(error => {
                        reject(error.message)
                        reject(error)
                    });

            })


        },

        updateSupervisor: ({commit}, supervisorInfo) => {
            commit('setStatusUpdateSupervisor','loading_update_supervisor')
            return new Promise((resolve, reject) => {

                // Send a POST request
                SuperviseurApi.updateSuperviseur(supervisorInfo)  // Send a POST request
                    .then(response => {

                        resolve(response.data)
                        commit('setMessageUpdateSupervisor',response.data.message)
                        commit('setStatusUpdateSupervisor',response.data.status)

                    })

                    .catch(error => {
                        reject(error)
                        commit('setStatusUpdateSupervisor',error.message)
                    });

            })


        },

        deleteSupervisor: ({commit}, supervisorId) => {
            commit('setStatusDeleteSupervisor','loading_delete_supervisor')
            return new Promise((resolve, reject) => {

                // Send a POST request
                SuperviseurApi.deleteSuperviseur(supervisorId)  // Send a POST request
                    .then(response => {
                        resolve(response.data)
                        commit('setMessageDeleteSupervisor',response.data.message)
                        commit('setMessageDeleteSupervisor',response.data.message)


                    })

                    .catch(error => {
                        reject(error)
                        commit('setStatusDeleteSupervisor',error.message)
                    });

            })


        },

        updateStatusSupervisor: ({commit}, supervisorInfo) => {
            commit('setStatusDeleteSupervisor','loading_delete_supervisor')
            return new Promise((resolve, reject) => {

                // Send a POST request
                SuperviseurApi.updateStatusSuperviseur(supervisorInfo)  // Send a POST request
                    .then(response => {
                        resolve(response.data)
                        commit('setMessageUpdateStatusSupervisor',response.data.message)
                        commit('setMessageUpdateStatusSupervisor',response.data.status)

                    })

                    .catch(error => {
                        reject(error)
                        commit('setStatusUpdateStatusSupervisor',error.message)
                    });

            })


        },



    },
    mutations:{
        setAllSupervisor(state,allSupervisor){
            state.allSupervisor = allSupervisor;
        },
        setStatusCodeGetSupervisor(state,statusCodeGetAllSupervisor){
            state.statusCodeGetAllallSupervisor = statusCodeGetAllSupervisor;
        },
        setMessageGetSupervisor(state,messageGetAllSupervisor){
            state.messageGetAllSupervisor = messageGetAllSupervisor;
        },
        setStatusSaveSupervisor(state,statusSaveSupervisor){
            state.statusSaveallSupervisor = statusSaveSupervisor
        },
        setMessageSaveSupervisor(state,messageSaveSupervisor){
            state.messageSaveSupervisor = messageSaveSupervisor;
        },
        setStatusUpdateSupervisor(state,statusUpdateSupervisor){
            state.statusUpdateallSupervisor = statusUpdateSupervisor;
        },
        setMessageUpdateSupervisor(state,messageUpdateSupervisor){
            state.messageUpdateSupervisor = messageUpdateSupervisor;
        },
        setStatusDeleteSupervisor(state,statusDeleteSupervisor){
            state.statusDeleteallSupervisor = statusDeleteSupervisor;
        },
        setMessageDeleteSupervisor(state,messageDeleteSupervisor){
            state.messageDeleteSupervisor = messageDeleteSupervisor;
        },
        setMessageUpdateStatusSupervisor(state,messageUpdateStatusSupervisor){
            state.messageUpdateStatusSupervisor = messageUpdateStatusSupervisor;
        },
        setStatusUpdateStatusSupervisor(state,statusUpdateStatusSupervisor){
            state.statusUpdateStatusSupervisor = statusUpdateStatusSupervisor;
        }
    },

    getters:{
        getAllSupervisor(state){return state.allSupervisor},
        getStatusCodeAllSupervisor(state){return state.statusCodeGetSupervisor},
        getMessageAllSupervisor(state){return state.messageGetSupervisor},
        getStatusSaveSupervisor(state){return state.statusSaveSupervisor},
        getMessageSaveSupervisor(state){return state.messageSaveSupervisor},
        getStatusUpdateSupervisor(state){return state.statusUpdateSupervisor},
        getMessageUpdateSupervisor(state){return state.messageUpdateSupervisor},
        getStatusDeleteSupervisor(state){return state.statusDeleteSupervisor},
        getMessageDeleteSupervisor(state){return state.messageDeleteSupervisor},
        getMessageUpdateStatusSupervisor(state){return state.messageUpdateStatusSupervisor},
        getStatusUpdateStatusSupervisor(state){return state.statusUpdateStatusSupervisor}


    }
}