//import api from '../../serviceApi/apiService'
export default {
    namespace: true,
    state: {
        visuel_fiche_autre: {
            id_repondant: "",
            id_survey: "",
            module: "",
        },
    },
    getters: {
        getVisuelFicheAutre(state) {
            return state.visuel_fiche_autre;
        }
    },
    mutations: {
        setVisuelFicheAutre(state, value) {
            state.visuel_fiche_autre = value;
        }
    },
    
    actions: {
        addVisuelFicheB: async function ({commit}, datas) {
            commit('setVisuelFicheAutre', { id_repondant: datas.id_repondant, id_survey: datas.id_survey, module: datas.module });
      

    },

    }

}    