import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import Vuebar from "vuebar";
import "./plugins/base";
import VueSkycons from "vue-skycons";
import InstantSearch from "vue-instantsearch";
import VueFeather from "vue-feather";

//axio
import axios from "axios";

import VueSweetalert2 from "vue-sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';

import excel from 'vue-excel-export'
import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);
Vue.use(excel);
//Vue.component("downloadExcel", JsonExcel);
Vue.use(VueFeather);
Vue.use(VueSkycons, {
  color: "#1e88e5",
});
Vue.use(InstantSearch);
Vue.use(VueSweetalert2);
Vue.config.productionTip = false;
Vue.use(Vuebar);
//axios.defaults.baseURL = "http://127.0.0.1:8000/api/";
axios.defaults.baseURL = "https://ebaseapi.piebarometre.digital/api/";

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
