export default (to, from, next) => {


 //console.log('okkzz0',this.$store.getters.getTokenUser);

  if (
    
    localStorage.getItem('token_fw') != null &&
    localStorage.getItem('token_fw').length > 0
  ) {
    next()
  } else {
    localStorage.removeItem('token_fw')
    next('/authentication/login')
  }
}
