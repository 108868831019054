import Vue from "vue";
import Vuex from "vuex";
import authData from './module/authData'
import dao_fiche_A from './module/dao_fiche_A'
import dao_fiche_autre from './module/dao_fiche_autre'
import dao_graphe from './module/dao_graphe'

import { CentralModule } from './modules/account/CentralModule'
import { SupervisorModule } from './modules/account/SupervisorModule'
import { SupervisorAreaModule } from './modules/area/SupervisorAreaModule'



Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    Sidebar_drawer: null,
    Customizer_drawer: false,
    SidebarColor: "white", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
    SidebarBg: "",
    navbarColor: "#fafafa",
    setHorizontalLayout: false, 
    // Horizontal layout
  },
  modules: {
    authData,
    dao_fiche_A,
    dao_fiche_autre,
    dao_graphe,
    CentralModule,
    SupervisorModule,
    SupervisorAreaModule,
  },
  mutations: {
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_NAVBAR_COLOR(state, payload) {
      state.navbarColor = payload;
    },
    SET_LAYOUT(state, payload) {
      state.setHorizontalLayout = payload;
    },
  },
  actions: {},
  getters: {},
});
