import api from '../../serviceApi/apiService'
export default {
    namespace: true,
    state: {
        infos_fiche_A: {
            canton: "",
            village: "",
            district: "",
            arrondissement: "",
            commune: "",
            departement: "",
            gps: "",
            nom_prenom_chef: "",
            age_chef: "",
            sexe_chef: "",
            phone_chef: "",
            nom_prenom_repondent: "",
            age_repondent: "",
            sexe_repondent: "",
            phone_repondent: "",
            languge_repondent: "",
            occupation_repondent: "",
            level_education_repondent: "",
            enquetteur: "xxxxxx",
        },
        visuel_fiche_A: {
            id_repondant: "",
            id_survey: "",
            module: "",
        }

   },

   getters: {
  
    getInfosFicheA(state) {
        return state.infos_fiche_A;
    },
    getVisuelFicheA(state) {
        return state.visuel_fiche_A;
    },
   
  },
    mutations: {
        setInfosFicheB(state, value) {
            state.infos_fiche_A = value;
        },
        setVisuelFicheA(state, value) {
            state.visuel_fiche_A = value;
        }
    },
    actions: {
        ListInfosFicheB: async function ({commit}, datas) {
            let fd = new FormData();
            fd.append('ID', datas.ID);
            const res = await api.createUpdatedata('parametre/add-repondant-module-etat', fd);
            commit('setInfosFicheB', res.repondants);
        },
        EtatFicheB: async function ({commit}, datas) {
            let fd = new FormData();
            fd.append('column', datas.column);
            fd.append('value', datas.value);
            fd.append('id_fiche', datas.id_fiche);
            const res = await api.createUpdatedata('parametre/add-repondant-module-etat', fd);
            commit('setInfosFicheA', res);
        },

        addVisuelFicheA: async function ({commit}, datas) {

            commit('setVisuelFicheA', { id_repondant: datas.id_repondant, id_survey: datas.id_survey, module: datas.module });
      
        }

        

    }


}    