//import api from '../../serviceApi/apiService'
export default {
    namespace: true,
    state: {
        visuel_graphe: {
            id_question: "0",
            libelle: "",
           
        },
    },
    getters: {
        getVisuelGraphe(state) {
            return state.visuel_graphe;
        }
        
    },
    mutations: {
        setVisuelGraphe(state, value) {
            state.visuel_graphe = value;
        }
        
    },
    
    actions: {
        addVisuelGraphe: async function ({commit}, datas) {
            commit('setVisuelGraphe', { id_question: datas.id_question, libelle: datas.libelle });
        }
        
    }

}    